<template>
  <div class="container">
    <alert
      v-if="feature"
      type="info"
      message="New feature! Quickly plan the amount of articles needed to be purchased on the given week"
      @close="feature = false"></alert>
    <div class="row">
      <div class="col-12">
        <div class="bg-white">
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                View purchases
              </div>
              <div class="card-options">
                <a
                  v-if="!edit"
                  href="#"
                  class="btn btn-link"
                  @click.prevent="edit = true">Edit filter</a>
              </div>
            </div>
            <div class="card-body">
              <dimmer :active="searching">
                <purchase-order-filter
                  ref="filter"
                  :view-only="true"
                  :edit="edit"
                  :filter.sync="filter"
                  @valid="data => applyFilter(data)"/>
              </dimmer>
            </div>
            <div v-if="!edit" class="mt-4">
              <div class="row">
                <div class="col">
                  <div class="card border-0 mb-0">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-lg-3">
                          <div class="card border-success">
                            <div class="card-body p-3 text-center">
                              <h1 class="m-0 mt-4 text-success font-weight-bold">{{totalPurchased}}</h1>
                              <div class="text-muted mb-4">Purchased articles</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="card border-primary">
                            <div class="card-body p-3 text-center">
                              <h1 class="m-0 mt-4 text-primary font-weight-bold">{{totalPartialPurchased}}</h1>
                              <div class="text-muted mb-4">Partially purchased articles</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="card border-orange">
                            <div class="card-body p-3 text-center">
                              <h1 class="m-0 mt-4 text-orange font-weight-bold">{{totalNotPurchased}}</h1>
                              <div class="text-muted mb-4">Pending for purchase articles</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="card">
                            <div class="card-body p-3 text-center">
                              <h1 class="m-0 mt-4 font-weight-bold">{{items.length}}</h1>
                              <div class="text-muted mb-4">Total articles</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-header pb-6 border-0">
                      <div class="card-options">
                        <table-filters
                          class="d-flex align-items-center"
                          :options="tableFilterList"
                          :filters.sync="resultFilters"
                          :items="items"></table-filters>
                      </div>
                    </div>
                    <div class="table-responsive mb-0" style="max-height: 35rem; overflow-y: auto;">
                      <table class="table table-outline table-bordered table-striped table-hover table-vcenter card-table sticky-table dataTables_wrapper">
                        <thead class="thead-light leading-none text-center">
                          <tr>
                            <th
                              class="border-left-0 font-weight-bold text-left py-4"
                              :class="column === 'article_name' ? `sorting_${sort}` : 'sorting'"
                              @click="() => sortColumn('article_name', 'string')">
                              Article
                            </th>
                            <th class="font-weight-bold py-4">Category</th>
                            <th class="font-weight-bold py-4">Supplier</th>
                            <th class="text-nowrap font-weight-bold py-4">Pack <br/>size</th>
                            <th
                              v-b-tooltip.hover
                              title="Ordering unit of measure"
                              class="cursor-pointer text-nowrap font-weight-bold py-4">
                              OUM <i class="fe fe-help-circle text-muted"></i>
                            </th>
                            <th
                              v-b-tooltip.hover
                              title="Minimum order quantity"
                              class="cursor-pointer text-nowrap font-weight-bold py-4">
                              MOQ <i class="fe fe-help-circle text-muted"></i>
                            </th>
                            <th
                              v-b-tooltip.hover
                              title="Suggested order quantity"
                              class="cursor-pointer text-nowrap font-weight-bold py-4">
                              SOQ <i class="fe fe-help-circle text-muted"></i>
                            </th>
                            <th
                              v-b-tooltip.hover
                              title="Stock on order"
                              class="cursor-pointer text-nowrap font-weight-bold py-4">
                              SOO <i class="fe fe-help-circle text-muted"></i>
                            </th>
                            <th class="font-weight-bold py-4">Delivery <br/>date</th>
                            <th class="font-weight-bold py-4 pr-3">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="article in filteredResults"
                            :key="article.article_id"
                            :class="{'table-success': article.isSelected}">
                            <td class="border-left-0" style="width: 20%">
                              <div class="d-flex flex-column">
                                <small><router-link :to="`/articles/${article.article_id}`" target="_blank">#{{article.article_id}}</router-link></small>
                                <strong>{{article.article_name}}</strong>
                                <small class="text-muted text-capitalize">{{article.material_type_name.split('-').join(' ')}}</small>
                              </div>
                            </td>
                            <td>{{article.category}}</td>
                            <td>{{article.supplier_name}}</td>
                            <td class="text-center">{{article.pack_size}}</td>
                            <td>{{article.replenishment_ordering_uom_name}}</td>
                            <td class="text-center">{{numeral(article.replenishment_minimum_order_quantity).format('0,0[.]00')}}</td>
                            <td class="text-center">{{numeral(article.suggested_order_quantity).format('0,0[.]00')}}</td>
                            <td class="text-center">{{numeral(article.stock_on_order).format('0,0[.]00')}}</td>
                            <td class="text-nowrap text-center">
                              {{moment(article.delivery_date).format('dddd')}}<br/>
                              {{moment(article.delivery_date).format('DD MMM YYYY')}}
                            </td>
                            <td class="text-center pr-3">
                              <div v-if="article.status === 'purchased'">
                                <div class="badge badge-success">Purchased</div>
                              </div>
                              <div v-else-if="article.status === 'partial'" :id="`partial_purchased_${article.article_id}`">
                                <div class="badge badge-primary">
                                  Partially purchased
                                </div>
                                <div class="small btn-link">Quick view</div>
                                <b-tooltip :target="`partial_purchased_${article.article_id}`" triggers="hover">
                                  <ul class="list-unstyled text-left">
                                    <li v-for="day in article.days" :key="day.name"><strong>{{day.name}}:</strong> {{day.purchased ? 'Yes' : 'No'}}</li>
                                  </ul>
                                </b-tooltip>
                              </div>
                              <div v-else>
                                <div class="badge badge-orange">Pending</div>
                              </div>
                            </td>
                          </tr>
                          <tr v-if="!filteredResults.length">
                            <td class="text-center" colspan="12">
                              <div class="d-flex align-items-center justify-content-center">
                                No articles found. <button class="btn btn-link pl-0" @click.prevent="edit = true">Search again.</button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import PurchaseOrderFilter from './filters/PurchaseOrderFilter';
import TableFilters from './filters/TableFilters';
import sort from '@/mixins/sort';
import Dimmer from '@/components/Dimmer.vue';
import {purchaseOrderRosterSummary} from '@/services';

export default {
  components: {
    Dimmer,
    PurchaseOrderFilter,
    TableFilters,
  },
  mixins: [
    sort,
  ],
  data() {
    return {
      edit: true,
      feature: true,
      filter: {
        days: [],
        weeklyMenu: null,
        purchaseType: '',
      },
      items: [],
      resultFilters: {
        categories: [],
        status: [],
        suppliers: [],
      },
      searching: false,
      tableFilterList: {
        category: {
          label: 'category',
          model: 'categories',
        },
        status: {
          label: 'status',
          model: 'status',
        },
        supplier_name: {
          label: 'supplier',
          model: 'suppliers',
        },
      },
    };
  },
  computed: {
    filteredResults() {
      let results = this.items || [];

      if (this.resultFilters.suppliers.length) { results = results.filter(item => this.resultFilters.suppliers.some(supplier => supplier === item.supplier_name)); }

      if (this.resultFilters.categories.length) { results = results.filter(item => this.resultFilters.categories.some(category => category === item.category)); }

      if (this.resultFilters.status.length) { results = results.filter(item => this.resultFilters.status.some(status => status === item.status)); }

      return results;
    },
    totalNotPurchased() {
      return this.items.filter(item => item.status === 'pending').length;
    },
    totalPartialPurchased() {
      return this.items.filter(item => item.status === 'partial').length;
    },
    totalPurchased() {
      return this.items.filter(item => item.status === 'purchased').length;
    },
  },
  methods: {
    async applyFilter() {
      this.searching = true;

      const params = {

        delivery_day: this.filter.days.map(day => day.id),
        type: this.filter.purchaseType,
        weekly_menu: this.filter.weeklyMenu.id,
      };

      try {
        const result = (await purchaseOrderRosterSummary.getByParameters(params)).items;

        this.items = result.map(item => {
          const days = item.days.filter(day => params.delivery_day.includes(day.id));

          let status = 'pending';

          if (days.every(data => data.purchased)) {
            status = 'purchased';
          }
          else if (days.some(data => data.purchased)) {
            status = 'partial';
          }

          return Object.assign(item, {

            days,
            status,
          });
        });
      }
      catch (ex) {
        console.error(ex);
      }
      finally {
        this.searching = false;
        this.edit = false;
      }
    },
  },
};
</script>

<style scoped>
    .big-checkbox >>> span > .fe {
        font-size: 2rem;
    }

    .sticky-table thead th {
        position: -webkit-sticky !important;
        position: sticky !important;
        top: 0;
        z-index: 20;
        border-right-width: 1px;
        border-left-width: 1px;
    }

    .sticky-table thead th:after {
        right: 2px;
        bottom: 1rem;
    }
    .sticky-table thead th:before {
        right: 2px;
        top: 1rem;
    }
</style>
